import React, { ReactElement, useRef } from 'react';
import {
  Page,
  ContentArea,
  Main,
  Body,
  IllustrationHeader,
  IllustrationNotice,
  IllustrationContent,
  getIllustrationFromType,
  IllustrationErrorTypes as ErrorTypes,
  TemplateActions as Actions,
  PageProps
} from '@cvent/carina/components/Templates';
import Router from 'next/router';
import { useTranslate } from 'nucleus-text';
import { ActionType } from '@cvent/carina/components/Templates/utils/helpers';

const actions = (returnUrl: React.MutableRefObject<string>, translate: (key: string) => string): ActionType[] => [
  {
    value: translate('error_go_back'),
    onClick: (): void => {
      if (returnUrl.current) {
        Router.push(returnUrl.current);
      } else {
        Router.back();
      }
    }
  }
];

export function NotFound(): ReactElement<PageProps> {
  const { translate } = useTranslate();
  const returnUrl: React.MutableRefObject<string> = useRef(null);
  return (
    <Page>
      <Main>
        <Body>
          <ContentArea>
            <IllustrationNotice illustration={getIllustrationFromType(ErrorTypes.ERROR_404)}>
              <IllustrationHeader>{translate('error_title')}</IllustrationHeader>
              <IllustrationContent>{translate('400_error_content')}</IllustrationContent>
              <Actions actions={actions(returnUrl, translate)} position="center" />
            </IllustrationNotice>
          </ContentArea>
        </Body>
      </Main>
    </Page>
  );
}

export default NotFound;
